<!--
 * @Autor: oops.liu
-->
<template>
  <div class="id_photo">
    <navbarbox>
      <van-nav-bar title="证照信息" left-arrow @click-left="toBack">
      </van-nav-bar>
    </navbarbox>
    <div class="nav_bar_box"></div>
    <div class="content_box">
      <scroller>
        <div class="content">
          <div class="seller_title ui-1px-b">
            云海易购商城网店经营者营业执照信息
          </div>
          <div class="info_box">
            <p>企业名称：{{ info.name }}</p>
            <p>营业执照注册号：{{ info.cerCode }}</p>
            <p>法定代表人名称：{{ info.cerMan }}</p>
            <p>营业执照所在地：{{ info.address }}</p>
            <p>企业注册资金：{{ info.cerMoney }}</p>
            <p>公司地址：{{ info.companyAddress }}</p>
            <p>营业执照经验范围：{{ info.cerRank }}</p>
          </div>
          <div class="id_photo">
            <img :src="info.certificate" alt="" />
          </div>
          <div class="hint">
            注：以上营业执照信息，根据国家工商总局《网络交易管理办法》要求对入驻商家营业执照信息进行公示，除企业名称通过认证之外，其余信息由卖家自行申报填写。如需进一步核实，请联系当地工商行政管理部门。
          </div>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import navbarbox from "@/components/common/navbarbox.vue";

export default {
  components: { navbarbox },

  props: {},
  data() {
    return {
      info: {},
    };
  },
  computed: {
    shopId() {
      return this.$store.state.seller.shopId;
    },
  },
  methods: {
    ...mapActions({
      load: "seller/getSellerInfo",
    }),
    toBack() {
      this.$router.back();
    },
  },
  created() {
    if (this.shopId) {
      this.load(this.shopId).then((data) => {
        this.info = data;
      });
    }
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.id_photo {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  ::v-deep .van-nav-bar__left {
    .van-icon {
      color: #333;
    }
  }
  .content_box {
    position: relative;
    width: 100%;
    height: calc(100vh - 76px);

    .content {
      width: 100%;
      padding-bottom: 30px;
      .seller_title {
        font-size: 14px;
        color: #515151;
        padding: 17px 0;
        text-align: center;
      }
      .info_box {
        width: 100%;
        box-sizing: border-box;
        padding: 13px 11px;
        p {
          font-size: 14px;
          color: #515151;
        }
      }
      .id_photo {
        img {
          width: 100%;
        }
      }
      .hint {
        font-size: 14px;
        padding: 13px 11px;
        color: #000;
      }
    }
  }
}
</style>
